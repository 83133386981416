import React from 'react'
import styled from 'styled-components'
import i18n from 'src/providers/i18n'

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <Content>{children}</Content>
    </LayoutContainer>
  )
}

export default Layout

const LayoutContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
`

const Content = styled.div`
  width: 100%;
  flex: 1;
`
