import React from 'react'
import Layout from 'src/theme/Layout'
import Metadata from 'src/components/Metadata'
import { META } from 'src/utils/seo'
import EmptyState from 'src/components/EmptyState'
import VerticalAlignContainer from 'src/components/VerticalAlignContainer'
import { useTranslation } from 'react-i18next'
import Paper from 'src/components/atoms/Paper'
import PATHS from 'src/utils/paths'
import Button from 'src/components/atoms/Button'
import { Link } from 'gatsby'

export default () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Metadata meta={META.notFound} />
      <VerticalAlignContainer>
        <Paper>
          <EmptyState text={t('notFoundPage.text')} />
          <Link to={PATHS.homepage}>
            <Button variant='contained' color='primary'>
              {t('notFoundPage.cta')}
            </Button>
          </Link>
        </Paper>
      </VerticalAlignContainer>
    </Layout>
  )
}
