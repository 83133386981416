export const META = {
  main: {
    title: 'PanDa Admin',
    og_title: 'PanDa Admin',
    description: 'SAAS for PanDa Admin',
    og_description: 'SAAS for PanDa Admin',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  notFound: {
    title: 'PanDa Admin',
    og_title: 'PanDa Admin',
    description: 'SAAS for PanDa Admin',
    og_description: 'SAAS for PanDa Admin',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  }
}
